/// /////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
/// /////////////////////////////////////////////
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { MsalGuard } from '@azure/msal-angular';

import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ResetLoginComponent } from './components/reset-login/reset-login.component';

/// New routes should be added in the guarded-routing.module.
const routes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'reset-login', component: ResetLoginComponent },
  {
    path: 'guard',
    loadChildren: () => import('./guarded/guarded.module').then(m => m.GuardedModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'intone',
    redirectTo: '/',
  },
  {
    path: 'intone/**',
    redirectTo: '/guard/intone/oban/home',
  },
  {
    path: 'play',
    redirectTo: '/',
  },
  {
    path: 'play/**',
    redirectTo: '/guard/intone/oban/home',
  },
  { path: '', component: LoginComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload', useHash: false,
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
