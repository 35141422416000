import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { CollapsibleConfig, CollapsibleToastComponent } from '../elements/collapsible-toast.component';

export enum ToastrType {
  Error = 0,
  Warning,
  Info,
  Success,
}

@Injectable()

export class NotificationService {
  private stagedErrorMessages: NotificationMessage[] = [];
  private stagedWarningMessages: NotificationMessage[] = [];
  private stagedInfoMessages: NotificationMessage[] = [];
  private stagedSuccessMessages: NotificationMessage[] = [];

  private isErrorTimerActive: boolean = false;
  private isWarningTimerActive: boolean = false;
  private isInfoTimerActive: boolean = false;
  private isSuccessTimerActive: boolean = false;

  private timeoutDuration: number = 2000;

  constructor(private toastr: ToastrService) {}

  permissionError(): void {
    this.error(
      'You do not have permission to access this page or complete the request. Visit <a href="https://studio.343i.xboxgamestudios.com/display/CD/Access+to+APIs%2c+Tools%2c+and+Admin+Sites" class="error-link" target="_blank">this page</a> for more information & access request links.',
      'Insufficient Permissions',
      { disableTimeOut: true, enableHtml: true, tapToDismiss: false, closeButton: true, toastClass: 'ngx-toastr permission-error-toastr inherits-display' },
    );
  }

  error(message?: string, title?: string, options?: Partial<IndividualConfig>): void {
    this.stageToast(ToastrType.Error, message, title, options);
    if (this.isErrorTimerActive) {
      return;
    }
    this.isErrorTimerActive = true;
    setTimeout(() => {
      this.displayErrorToastr();
      this.isErrorTimerActive = false;
      this.stagedErrorMessages = [];
    }, this.timeoutDuration);
  }

  warning(message?: string, title?: string, options?: Partial<IndividualConfig>): void {
    this.stageToast(ToastrType.Warning, message, title, options);
    if (this.isWarningTimerActive) {
      return;
    }
    this.isWarningTimerActive = true;
    setTimeout(() => {
      this.displayWarningToastr();
      this.isWarningTimerActive = false;
      this.stagedWarningMessages = [];
    }, this.timeoutDuration);
  }

  info(message?: string, title?: string, options?: Partial<IndividualConfig>): void {
    this.stageToast(ToastrType.Info, message, title, options);
    if (this.isInfoTimerActive) {
      return;
    }
    this.isInfoTimerActive = true;
    setTimeout(() => {
      this.displayInfoToastr();
      this.isInfoTimerActive = false;
      this.stagedInfoMessages = [];
    }, this.timeoutDuration);
  }

  success(message?: string, title?: string, options?: Partial<IndividualConfig>): void {
    this.stageToast(ToastrType.Success, message, title, options);
    if (this.isSuccessTimerActive) {
      return;
    }
    this.isSuccessTimerActive = true;
    setTimeout(() => {
      this.displaySuccessToastr();
      this.isSuccessTimerActive = false;
      this.stagedSuccessMessages = [];
    }, this.timeoutDuration);
  }

  clear(): void {
    this.toastr.clear();
  }

  private async displayErrorToastr(): Promise<void> {
    if (this.stagedErrorMessages.length === 1) {
      this.toastr.error(this.stagedErrorMessages[0].message, this.stagedErrorMessages[0].title, this.stagedErrorMessages[0].options);
    } else {
      const title = 'Multiple errors occured';
      const options: Partial<CollapsibleConfig> = {
        disableTimeOut: true,
        closeButton: true,
        enableHtml: true,
        toastClass: 'ngx-toastr toast-error collapsible-toastr inherits-display',
        tapToDismiss: false,
        extraMessages: [...this.stagedErrorMessages.slice(1).map(msg => msg.message)],
        toastComponent: CollapsibleToastComponent,
      };
      this.toastr.show(this.stagedErrorMessages[0].message, title, options);
    }
  }

  private displayWarningToastr(): void {
    if (this.stagedWarningMessages.length === 1) {
      this.toastr.warning(this.stagedWarningMessages[0].message, this.stagedWarningMessages[0].title, this.stagedWarningMessages[0].options);
    } else {
      const title = 'Multiple warnings occured';
      const options: Partial<CollapsibleConfig> = {
        disableTimeOut: true,
        closeButton: true,
        enableHtml: true,
        toastClass: 'ngx-toastr toast-warning collapsible-toastr inherits-display',
        tapToDismiss: false,
        extraMessages: [...this.stagedWarningMessages.slice(1).map(msg => msg.message)],
        toastComponent: CollapsibleToastComponent,
      };
      this.toastr.show(this.stagedWarningMessages[0].message, title, options);
    }
  }

  private displayInfoToastr(): void {
    if (this.stagedInfoMessages.length === 1) {
      this.toastr.info(this.stagedInfoMessages[0].message, this.stagedInfoMessages[0].title, this.stagedInfoMessages[0].options);
    } else {
      const title = 'Multiple infos occured';
      const options: Partial<CollapsibleConfig> = {
        disableTimeOut: true,
        closeButton: true,
        enableHtml: true,
        toastClass: 'ngx-toastr toast-info collapsible-toastr inherits-display',
        tapToDismiss: false,
        extraMessages: [...this.stagedInfoMessages.slice(1).map(msg => msg.message)],
        toastComponent: CollapsibleToastComponent,
      };
      this.toastr.show(this.stagedInfoMessages[0].message, title, options);
    }
  }

  private displaySuccessToastr(): void {
    if (this.stagedSuccessMessages.length === 1) {
      this.toastr.success(this.stagedSuccessMessages[0].message, this.stagedSuccessMessages[0].title, this.stagedSuccessMessages[0].options);
    } else {
      const title = 'Multiple successes occured';
      const options: Partial<CollapsibleConfig> = {
        disableTimeOut: true,
        closeButton: true,
        enableHtml: true,
        toastClass: 'ngx-toastr toast-success collapsible-toastr inherits-display',
        tapToDismiss: false,
        extraMessages: [...this.stagedSuccessMessages.slice(1).map(msg => msg.message)],
        toastComponent: CollapsibleToastComponent,
      };
      this.toastr.show(this.stagedSuccessMessages[0].message, title, options);
    }
  }

  private stageToast(type: ToastrType, message?: string, title?: string, options?: Partial<IndividualConfig>): void {
    if (type === ToastrType.Error && !this.stagedErrorMessages.some(toast => toast.message === message)) {
      this.stagedErrorMessages.push({ message, title, options });
    } else if (type === ToastrType.Warning && !this.stagedWarningMessages.some(toast => toast.message === message)) {
      this.stagedWarningMessages.push({ message, title, options });
    } else if (type === ToastrType.Info && !this.stagedInfoMessages.some(toast => toast.message === message)) {
      this.stagedInfoMessages.push({ message, title, options });
    } else if (type === ToastrType.Success && !this.stagedSuccessMessages.some(toast => toast.message === message)) {
      this.stagedSuccessMessages.push({ message, title, options });
    }
  }
}

export interface NotificationMessage {
  message?: string;
  title?: string;
  options?: Partial<IndividualConfig>;
}
