/// /////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
/// /////////////////////////////////////////////
import { Component, OnInit } from '@angular/core';
import { BroadcastService } from '@azure/msal-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { TENANTS } from '../../tenants.constants';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'login',
  templateUrl: './login.template.html',
})
export class LoginComponent implements OnInit {
  isLoggedIn: boolean;
  showTenants: boolean = false;
  spartanToken: string;
  canRetry: boolean = false;
  subscription: any;

  public loginError: any = null;
  public state: any = {

    signInError: false,
  };

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute, private broadcastService: BroadcastService) { }

  ngOnInit() {
    this.subscription = this.broadcastService.subscribe('msal:loginSuccess',
      (payload): void => {
        console.debug(`login success ${JSON.stringify(payload)}`);
        this.isLoggedIn = true;
      });

    if (this.route.snapshot.queryParams.useMsft) {
      console.debug('login: MSFT TENANT OVERRIDE');
      const msftTenant = TENANTS.filter(t => t.id === AuthService.msftTenant)[0];
      this.auth.changeTenantId(msftTenant.id);
      localStorage.setItem('allowLegacyTenant', msftTenant.id);
    }

    console.debug('login: on init');
    this.auth.handleReturn(); // handles return if any

    console.debug(`login: is logged in? ${this.isLoggedIn}`);

    this.isLoggedIn = this.auth.check();

    if (this.isLoggedIn) {
      console.debug('login: fetching ST');
      let environment = localStorage.getItem('lastEnv');
      const lastTenantId = localStorage.getItem('tenant-id');
      const currentTenant = TENANTS.filter(t => t.id === this.auth.aadTenantId)[0];
      console.debug(`Last t(${lastTenantId}), current t(${currentTenant.id})`);

      if (!lastTenantId || lastTenantId !== currentTenant.id || !environment) {
        environment = currentTenant.defaultEnv;
      }

      console.debug(`ENV AT LOGIN: ${environment} under t(${this.auth.aadTenantId})`);
      const stv4Host = `https://settings${currentTenant.stv4Affix}.svc.halowaypoint.com`;
      const stv4Aud = environment.toLocaleLowerCase() === 'prod' ? 'https://settings.svc.halowaypoint.com/spartan-token' : 'https://settings.test.svc.halowaypoint.com/spartan-token';
      const isTestEnv = !environment.toLowerCase().startsWith('prod');
      this.auth.getSpartanToken(isTestEnv, stv4Aud, stv4Host, environment.toLowerCase()).then(token => {
        this.spartanToken = token.SpartanToken;
        const title = localStorage.getItem('lastTitle') || 'oban';
        const redirectToPath = localStorage.getItem('post-login-path');
        if (redirectToPath) {
          const url = `${window.location.protocol}//${window.location.host}/${redirectToPath}`;
          console.debug(`redirecting to ${url}`);
          this.router.navigateByUrl(redirectToPath).then(() => localStorage.removeItem('post-login-path'));
        } else {
          console.debug('defaulting to /home');
          this.router.navigate([`/guard/${environment}/${title}/home`]);
        }
      }).catch(e => {
        console.error(e);
        this.state.signInError = true;
        this.loginError = e;
      });
    } else {
      const requestedTenant = localStorage.getItem('nextTenant');
      if (requestedTenant) {
        localStorage.removeItem('nextTenant');
        this.auth.login().then();
      } else {
        this.showTenants = true;
      }
      this.showTenants = !localStorage.getItem('tenant-id');
    }

    setTimeout(() => {
      this.canRetry = true;
    }, 1000);
  }

  loginStart(): void {
    this.auth.login().then();
    this.auth.handleReturn();
  }

  resetLogin(): void {
    localStorage.clear();
    window.location.replace(`${window.location.protocol}//${window.location.host}`);
  }

  ngOnDestroy() {
    // disconnect from broadcast service on component destroy
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
