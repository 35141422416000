import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

@Component({ template: '' })

export class ResetLoginComponent implements OnInit {
  constructor(
    private router: Router,
  ) {}

  ngOnInit() {
    localStorage.clear();
    this.router.navigate(['/']);
  }
}
