<div class="tenant-menu section" style="text-align: center;">
  <h2 class="is-size-5">Select Your Tenant to Login</h2>
  <ul>
    <li style="padding-bottom: 1em;" *ngFor="let tenant of TENANTS; let i=index">
      <button class="button is-outlined" style="width: 250pt;" (click)="selectTenant(i)"
        [class]="tenant.class">{{tenant.name}}</button>
      <br />
      <span class="helper-small">
        {{tenant.description}}
      </span>
    </li>
  </ul>
  <br />
  <strong>Not sure or don't have permission?</strong> <br /> <a href="https://studio.343i.xboxgamestudios.com/pages/viewpage.action?spaceKey=CD&title=Access+to+APIs%2C+Tools%2C+and+Admin+Sites">Head here</a> to learn more and gain access.
</div>