import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TENANTS } from '../../tenants.constants';

@Component({
  templateUrl: './login-tenant-selection.template.html',
  selector: 'login-tenant-selection',
})
/**
 * TenantSelectionComponent - Renders a button to toggle a dropdown which allows the user to select a new tenant to work in
 */
export class LoginTenantSelectionComponent {
  readonly TENANTS = TENANTS.filter(t => !t.hidden);

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  /**
   * selectTenant - Click handler for tenant dropdown. This will set the current tenant within the auth service,
   * then refresh the page to process the login in the new tenant.
   * @param index - Index within the TENANTS array of the tenant which was selected
   */
  selectTenant(index: number): void {
    this.auth.changeTenantId(this.TENANTS[index].id, this.TENANTS[index].address);
    this.auth.login();
    this.auth.handleReturn();
  }
}
